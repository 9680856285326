<template>
  <div>
    <van-tabbar v-model="active" z-index="500" route @change="onChange">
      <van-tabbar-item icon="home-o" to="/home">{{ $t('foot.home') }}</van-tabbar-item>
      <van-tabbar-item icon="balance-pay" to="/recharge">{{ $t('foot.deposit') }}</van-tabbar-item>
      <van-tabbar-item icon="/static/image/iconKefu.da12a10e52caa3da81e8cbe383247f67.png" @click="goTo">{{ $t('foot.customer') }}</van-tabbar-item>
      <van-tabbar-item icon="user-o" to="/mine">{{ $t('foot.my') }}</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: 0,
	  kf_url:null
    };
  },
  created() {

    if (sessionStorage.getItem("token")) {
      this.getUserInfo();
    }
   },
  methods: {
    onChange(index) {
    },
	goTo(){
		window.location.href = this.kf_url;
	},
	getUserInfo() {
	  let that = this;
	  that.$apiFun.post("/api/user", {}).then((res) => {
	    if (res.code === 200) {
	      let userInfo = res.data;
		  that.kf_url = userInfo.kf_url;


	    }
	  });
	}
  },
};
</script>
