<template>
  <div style="width: 100%; min-height: 100vh; background: rgb(237, 241, 255)">
    <van-nav-bar style="position: fixed; top: 0; left: 0; width: 100%; background-color: #edf2fd" :title="$t('addUsdtCard.AddUSDT')" left-arrow @click-left="$router.back()" />
    <div style="height: 46px"></div>
    <div class="usrse">
      <div class="hgs">
        <div class="nams">{{ $t('addUsdtCard.address') }}</div>
          <div data-v-a12ec382="" class="van-cell-group van-hairline--top-bottom">
            <div data-v-a12ec382="" class="van-cell van-field">
              <div class="van-field__body"><input type="text" readonly onfocus="this.removeAttribute('readonly');" auto-complete="off" onblur="this.setAttribute('readonly',true);" v-model="bank_no" :placeholder="$t('addUsdtCard.enterAddress')" class="van-field__control" /></div>

            </div>
          </div>
      </div>
    </div>
    <div style="font-size: 0.24rem; color: #98a5b3; box-sizing: border-box; padding: 6px">{{ $t('addUsdtCard.tip') }}</div>
    <div class="usrse">
      <div class="hgs">
        <div class="nams sc">
          {{ $t('addUsdtCard.WalletProtocol') }}
          <div
            :class="bank == 'trc-20' ? ' ssa acti' : 'ssa'"
            @click="changXiyi('trc-20')"
          >
            TRC20
          </div>
          <div
            :class="bank == 'erc-20' ? ' ssa acti' : 'ssa'"
            style="margin-left: 0.5rem"
            @click="changXiyi('erc-20')"
          >
            ERC20
          </div>
        </div>
        <div style="border-bottom: 1px solid #f2f2f2"></div>
      </div>
      <van-button type="info" style="margin-top: 20px; width: 100%" @click="bindCard">{{ $t('addUsdtCard.ConfirmAdd') }}</van-button>
      <div style="height: 40px"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'addBankCard',
  data() {
    return {
      bank_no: '',
      bank: 'trc-20',
    };
  },
  created() {
    let that = this;
  },
  methods: {
    changXiyi(val) {
      this.bank = val;
    },
    bindCard() {
      let that = this;
      if (!that.bank_no) {
        that.$parent.showTost(0, $t('addUsdtCard.enterAddress'));
        return;
      }
      that.$parent.showLoading();
      that.$apiFun
        .post('/api/bindcard', { bank_no: that.bank_no, bank: 'USDT', type: that.bank })
        .then(res => {
          if (res.code != 200) {
            that.$parent.showTost(0, res.message);
          }
          if (res.code == 200) {
            that.$parent.showTost(1, $t('addUsdtCard.BindingSuccessful'));
            that.$router.back();
          }
          that.$parent.hideLoading();
        })
        .catch(res => {
          that.$parent.hideLoading();
        });
    },
  },
  mounted() {
    let that = this;
  },
  updated() {
    let that = this;
  },
};
</script>

<style lang="scss" scoped>
.usrse {
  background: #fff;
  box-sizing: border-box;
  padding: 6px 20px 0;
  .nams {
    font-size: 0.38rem;
    color: #000;
    vertical-align: middle;
    margin-top: 10px;
    font-weight: 700;
  }
  .imgsa {
    position: relative;
    height: 2rem;
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: 0.2rem;
    .bisn {
      width: 0.8rem;
      position: absolute;
      bottom: 0.3rem;
      left: 1.4rem;
    }
    img {
      width: 2rem;
      border-radius: 50%;
    }
  }
}
.sc {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  .ssa {
    border-radius: 5px;
    border: 1px solid #f1f1f1;
    width: 2rem;
    height: 1rem;
    line-height: 1rem;
    font-size: 0.4rem;
    font-weight: 700;
    text-align: center;
    margin-left: 1rem;
  }
  .acti {
    color: #597ef7 !important;
    border: 1px solid #597ef7 !important;
  }
}
[class*='van-hairline']:after {
  border: none;
}
</style>